import React from "react";
import { IDS_HEADER_MOCKDATA } from "./components/IdsHeader/mockdata";
import { IdsHeader } from "./components/IdsHeader/IdsHeader";

const ErrorPage = () => {
  return (
    <React.Fragment>
      <IdsHeader data={IDS_HEADER_MOCKDATA} />
      <h1>Something went Wrong</h1>
    </React.Fragment>
  );
};

export default ErrorPage;
