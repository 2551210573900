import React from "react";
import useStyles from "./UserMenu.styles";
import { IdsHeaderData } from "../types";
import { Menu, Divider } from "@mantine/core";
import { UserLabel } from "../UserLabel/UserLabel";

interface UserMenuProps {
  user: IdsHeaderData["user"];
  user_links: IdsHeaderData["user_links"];
}

export default function UserMenu({ user, user_links }: UserMenuProps) {
  const { classes } = useStyles();

  if (!user) {
    return null;
  }

  const links = user_links.map((link, index) =>
    link.separator ? (
      <Divider key={index} />
    ) : (
      <Menu.Item
        component="a"
        href={link.link}
        target={link.external ? "_blank" : null!}
        className={classes.link}
        key={index}
      >
        {link.title}
      </Menu.Item>
    )
  );

  return (
    <div className={classes.wrapper}>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <div className={classes.label}>{<UserLabel user={user} />}</div>
        </Menu.Target>
        <Menu.Dropdown>{links}</Menu.Dropdown>
      </Menu>
    </div>
  );
}
