import React, { useState, useEffect } from "react";
import { Grid, Col } from "@mantine/core";
import { TextInput } from "@mantine/core";
import { BasicInputObtType } from "../Models/ObtTypes";
import { validatePhoneNumber } from "../Libraries/ValidationUtils";

const PhoneNumber: React.FC<BasicInputObtType> = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
    props.saveInput({ [props.name]: event.currentTarget.value });

    if (props.required) {
      if (validatePhoneNumber(event.currentTarget.value)) {
        setErrorMessage("");
      } else {
        setErrorMessage("Invalid Number");
      }
    }
  };

  useEffect(() => {
    if (props.value !== undefined) {
      setInputValue(props.value);
      props.saveInput({ [props.name]: props.value });
    }
  }, [props.value]);

  return (
    <Grid>
      <Col span={4}>
        {props.label}
        <span style={{ color: "red" }}> {props.required ? "*" : ""} </span>
      </Col>

      <Col span={8}>
        <TextInput
          placeholder={props.placeHolderText}
          icon={"#"}
          required={props.required}
          onBlur={handleChange}
          value={inputValue}
          onChange={handleChange}
          error={errorMessage}
        />
      </Col>
    </Grid>
  );
};

export default PhoneNumber;
