import React from "react";

interface TimeSlotContextType {
  timeSlotData: Date[] | [];
  setTimeSlotDataValue: (dateArr: Date[]) => void;
}

const TimeSlotContext = React.createContext<TimeSlotContextType>({
  timeSlotData: [],
  setTimeSlotDataValue: (dateArr: Date[]) => {},
});

export { TimeSlotContext };
