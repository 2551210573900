import { Grid } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import {
  React,
  useEffect,
  useState,
  GridLayout,
  LoadingOverlay,
  Tabs,
  tabStyle,
} from "../../Import";
import "../../../Translation/i18n";
import AppointmentsTable from "./AppointmentsTable";
import AppointmentForm from "./AppointmentForm";

const IplObt: React.FC = () => {
  const { t } = useTranslation();
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [projectKey] = useState(window.location.pathname.split("/")[1]);
  const { tab } = useParams();
  const [currentTab, setCurrentTab] = useState(tab);
  const navigate = useNavigate();

  useEffect(() => {
    if(tab !== currentTab) {
      setCurrentTab(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const updateCurrentTab = (tab: string) => {
    setCurrentTab(tab);
    navigate(`/${projectKey}/${tab}`);
  };

  const updateLoadingState = (newLoadingState: boolean) => {
    setLoaderVisible(newLoadingState);
  };

  return (
    <Tabs
      unstyled
      value={currentTab}
      styles={tabStyle}
      onTabChange={(value) => updateCurrentTab(value!)}
    >
      <LoadingOverlay visible={loaderVisible} overlayBlur={2} />
      <GridLayout type="tabs">
        <Tabs.List>
          <Tabs.Tab value="form">{t("new_appointment")}</Tabs.Tab>
          <Tabs.Tab value="list">{t("my_appointments")}</Tabs.Tab>
        </Tabs.List>
      </GridLayout>

      <Tabs.Panel value="form">
        <AppointmentForm
          translation={t}
          tabValue={currentTab}
          projectKey={projectKey}
          id=""
          updateLoadingState={updateLoadingState}
        />
      </Tabs.Panel>

      <Tabs.Panel value="list" pt="xs">
        <Grid columns={18}>
          <Grid.Col span={16} offset={1}>
            <div className="borderline">
              <AppointmentsTable
                projectKey={projectKey}
                updateLoadingState={updateLoadingState}
                tabValue={currentTab}
              />
            </div>
          </Grid.Col>
        </Grid>
      </Tabs.Panel>
    </Tabs>
  );
};

export default IplObt;
