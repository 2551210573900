import React from "react";
import { Grid, Col } from "@mantine/core";
import { Button } from "@mantine/core";
import { BasicFormObtType } from "../Models/ObtTypes";

const FormActions: React.FC<BasicFormObtType> = (props) => {
  return (
    <Grid>
      <Col span={4}>
        <Button variant="default">{props.cancelText}</Button>
      </Col>
      <Col span={8}>
        <Button color="gray" className="float-end" type="submit">
          {props.submitText}
        </Button>
      </Col>
    </Grid>
  );
};

export default FormActions;
