import { createStyles } from "@mantine/core";
import { IDS_HEADER_COLORS } from "./colors";

export const DESKTOP_HEIGHT = 115;
export const MOBILE_HEIGHT = 65;
export const BREAKPOINT = 992;

export default createStyles((theme, _params, getRef) => {
  const linkLabel = getRef("linkLabel");

  return {
    wrapper: {
      paddingTop: 26,
      backgroundColor: IDS_HEADER_COLORS.charcoal,
      height: DESKTOP_HEIGHT,
      [`@media screen and (max-width: ${BREAKPOINT}px)`]: {
        height: MOBILE_HEIGHT,
        paddingTop: 16,
      },
    },
    logo: {},
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 15,
    },
    link: {
      display: "inline-block",
      textDecoration: "none",
      fontSize: 16,
      paddingTop: 2,
      paddingBottom: 9,
      lineHeight: 1,
      "&:not(:first-of-type)": {
        marginLeft: 30,
      },
      "&:hover": {
        [`& .${linkLabel}`]: {
          color: "#fff",
        },
      },
    },
    linkLabel: {
      ref: linkLabel,
      color: theme.colors.gray[3],
    },
    linkActive: {
      borderBottom: `4px solid ${IDS_HEADER_COLORS.gold}`,
      fontWeight: 500,
      [`& .${linkLabel}`]: {
        color: theme.white,
      },
    },
    nav: {
      whiteSpace: "nowrap",
      [`@media screen and (max-width: ${BREAKPOINT}px)`]: {
        display: "none",
      },
    },
  };
});
