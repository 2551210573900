import React, { useState, useEffect } from "react";
import { BasicInputObtType } from "../Models/ObtTypes";
import { Grid, Col } from "@mantine/core";
import { Textarea } from "@mantine/core";

const TextArea: React.FC<BasicInputObtType> = (props) => {
  const [inputValue, setInputValue] = useState("");
  const handleChange = (event: any) => {
    setInputValue(event.currentTarget.value);
    props.saveInput({ [props.name]: event.currentTarget.value });
  };

  useEffect(() => {
    if (props.value !== undefined) {
      setInputValue(props.value);
      props.saveInput({ [props.name]: props.value });
    }
  }, [props.value]);

  return (
    <Grid>
      <Col span={4}>
        {props.label}
        <span style={{ color: "red" }}> {props.required ? "*" : ""} </span>
      </Col>
      <Col span={8}>
        <Textarea
          placeholder={props.placeHolderText}
          required={props.required}
          onBlur={handleChange}
          value={inputValue}
          onChange={handleChange}
        />
      </Col>
    </Grid>
  );
};

export default TextArea;
