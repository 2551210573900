import { createStyles } from "@mantine/core";
import { BREAKPOINT } from "../IdsHeader.styles";

export const DROPDOWN_TRANSITION_DURATION = 150;

export default createStyles((theme) => ({
  wrapper: {
    userSelect: "none",
    [`@media screen and (max-width: ${BREAKPOINT}px)`]: {
      display: "none",
    },
  },
  link: {
    cursor: "pointer",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    color: theme.colors.gray[7],
    "&:hover": {
      backgroundColor: theme.colors.gray[0],
      color: theme.black,
    },
  },
  label: {
    color: theme.white,
    marginRight: theme.spacing.xs,
  },
}));
