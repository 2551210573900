import "react-calendar/dist/Calendar.css";
import React from "react";
import { IDS_HEADER_MOCKDATA } from "../IdsHeader/mockdata";
import { IdsHeader } from "../IdsHeader/IdsHeader";
import { Outlet } from "react-router-dom";

const ObtLayout = () => {
  return (
    <React.Fragment>
      <IdsHeader data={IDS_HEADER_MOCKDATA} />
      <Outlet />
    </React.Fragment>
  );
};

export default ObtLayout;
