import { createStyles } from "@mantine/core";

export default createStyles((theme) => ({
  wrapper: {
    WebkitTapHighlightColor: "transparent",
    color: theme.white,
    appearance: "none",
    backgroundColor: "transparent",
    border: 0,
    outline: 0,
    padding: 0,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  label: {
    color: theme.white,
    marginRight: theme.spacing.xs,
  },
  avatar: {
    marginRight: theme.spacing.xs,
  },
}));
