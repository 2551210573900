import { MantineTheme } from "@mantine/core"
export const tabStyle = (theme: MantineTheme) => ({
  tab: {
    ...theme.fn.focusStyles(),
    marginTop: "1%",
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[9],
    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[4]}`,
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    cursor: 'pointer',
    fontSize: theme.fontSizes.sm,
    display: 'flex',
    alignItems: 'center',

    '&:disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },

    '&:not(:first-of-type)': {
      borderLeft: 0,
    },



    '&[data-active]': {
      backgroundColor: theme.colors.gray[7],
      borderColor: theme.colors.gray[7],
      color: theme.white,
    },
  },

  tabIcon: {
    marginRight: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
  },

  tabsList: {
    display: 'flex',
  },

})
