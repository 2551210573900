import React, { forwardRef } from "react";
import { Avatar, Text } from "@mantine/core";
import useStyles from "./UserLabel.styles";
import { IdsHeaderData } from "../types";

interface UserLabelProps extends React.ComponentPropsWithoutRef<"button"> {
  user: IdsHeaderData["user"];
}

export const UserLabel = forwardRef<HTMLButtonElement, UserLabelProps>(
  ({ user, ...others }: UserLabelProps, ref) => {
    const { classes } = useStyles();

    return (
      <button className={classes.wrapper} type="button" ref={ref} {...others}>
        <Text className={classes.label}>{user.title}</Text>

        <Avatar className={classes.avatar} src={user.image} size={25} />
      </button>
    );
  }
);
