import React from "react";
import Timeslot from "./Timeslot";
import { ScrollArea } from "@mantine/core";
import { Grid } from "@mantine/core";
import { TimeslotsType } from "../Models/ObtTypes";
import { groupArr } from "../Libraries/Util";

const Timeslots: React.FC<TimeslotsType> = (props) => {
  return (
    <ScrollArea type="always" style={{ margin: "1%", height: 450 }}>
      {props.slots
        ? groupArr(props.slots, 2).map((slot, index) => {
            return (
              <Grid columns={18} key={index}>
                <Grid.Col span={1}></Grid.Col>
                <Grid.Col span={7}>
                  <Timeslot key={index + "timeslot"} slot={slot[0]} />
                </Grid.Col>
                <Grid.Col span={1}></Grid.Col>
                <Grid.Col span={1}></Grid.Col>
                <Grid.Col span={7}>
                  {typeof slot[1] !== "undefined" ? (
                    <Timeslot key={index + "timeslot"} slot={slot[1]} />
                  ) : (
                    ""
                  )}
                </Grid.Col>
                <Grid.Col span={1}></Grid.Col>
              </Grid>
            );
          })
        : "No Time slots available to display (or) Please select any date to get time slots"}
    </ScrollArea>
  );
};

export default Timeslots;
