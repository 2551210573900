import { IdsHeaderData } from "./types";

export const IDS_HEADER_MOCKDATA: IdsHeaderData = {
  logo: {
    image: "https://picsum.photos/200/300", // please do not use this as image, it is just a screenshot of mockup
    link: "https://google.com",
    external: true,
  },

  main_links: [
    {
      title: "Patients",
      link: "https://google.com",
      active: false,
      external: true,
    },
    {
      title: "Studies",
      link: "https://google.com",
      active: false,
      external: true,
    },
    {
      title: "Account",
      link: "https://google.com",
      active: false,
      external: true,
    },
    {
      title: "WebStore",
      link: "https://google.com",
      active: false,
      external: true,
    },
    {
      title: "Education",
      link: "https://google.com",
      active: false,
      external: true,
    },
    {
      title: "Support",
      link: "https://google.com",
      active: true,
      external: true,
    },
    {
      title: "Collaboration",
      link: "https://google.com",
      active: false,
      external: true,
    },
  ],

  user: {
    title: "Hello, Doctor Align",
    image: "https://picsum.photos/200/300",
  },

  user_links: [
    { title: "Dr. Profile", link: "https://google.com", external: true },
    {
      title: "Clinical Preferences",
      link: "https://google.com",
      external: true,
    },
    {
      title: "Practice development",
      link: "https://google.com",
      external: true,
    },
    { title: "Invisalign Pro", link: "https://google.com", external: true },
    { separator: true },
    { title: "Promotions", link: "https://google.com", external: true },
    {
      title: "Invisalign Marketing Site",
      link: "https://google.com",
      external: true,
    },
    { title: "SmileView setup", link: "https://google.com", external: true },
    { title: "Help", link: "https://google.com", external: true },
    { separator: true },
    { title: "Logout", link: "https://google.com", external: true },
  ],
};
