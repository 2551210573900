import { createStyles } from "@mantine/core";
import { MOBILE_HEIGHT, BREAKPOINT } from "../IdsHeader.styles";
import { IDS_HEADER_COLORS } from "../colors";

export const MENU_TRANSITION_DURATION = 150;
export const USER_DROPDOWN_TRANSITION = 150;
export const USER_LINK_HEIGHT = 32;
export const USER_SEPARATOR_HEIGHT = 11;
export const USER_DROPDOWN_SPACING = 10;

export default createStyles((theme, _params, getRef) => {
  const mainLinkLabel = getRef("mainLinkLabel");

  return {
    wrapper: {
      [`@media screen and (min-width: ${BREAKPOINT}px)`]: {
        display: "none",
      },
    },
    menu: {
      position: "absolute",
      zIndex: 10,
      left: 0,
      right: 0,
      paddingBottom: 15,
      top: MOBILE_HEIGHT,
      backgroundColor: IDS_HEADER_COLORS.charcoal,
      transformOrigin: "top",
      transition: `transform ${MENU_TRANSITION_DURATION}ms ease`,
    },
    mainLink: {
      WebkitTapHighlightColor: "transparent",
      display: "block",
      textDecoration: "none",
      fontSize: 16,
      padding: "10px 11px",
      lineHeight: 1,
      borderLeft: "4px solid transparent",
      "&:hover": {
        [`& .${mainLinkLabel}`]: {
          color: theme.white,
        },
      },
    },
    mainLinkLabel: {
      ref: mainLinkLabel,
      color: theme.colors.gray[4],
    },
    mainLinkActive: {
      borderLeftColor: IDS_HEADER_COLORS.gold,
      fontWeight: 500,

      [`& .${mainLinkLabel}`]: {
        color: theme.white,
      },
    },
    userLabel: {
      padding: "10px 15px",
    },
    userDropdown: {
      overflow: "hidden",
      backgroundColor: theme.white,
      transition: `max-height ${USER_DROPDOWN_TRANSITION}ms ease`,
    },
    userDropdownInner: {
      paddingTop: USER_DROPDOWN_SPACING / 2,
      paddingBottom: USER_DROPDOWN_SPACING / 2,
    },
    userLink: {
      height: USER_LINK_HEIGHT,
      cursor: "pointer",
      textDecoration: "none",
      lineHeight: 1,
      display: "flex",
      alignItems: "center",
      padding: "0 15px",
      color: theme.colors.gray[7],
      "&:hover": {
        backgroundColor: theme.colors.gray[0],
        color: theme.black,
      },
    },
  };
});
