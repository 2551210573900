import React, { useContext, useEffect, useState } from "react";
import { Grid, Col } from "@mantine/core";
import { TextInput } from "@mantine/core";
import { TimeSlotContext } from "../Store/TimeSlotContext";

const AppointmentTime: React.FC<{ value?: [Date, Date] }> = (props) => {
  const timeSlotContext = useContext(TimeSlotContext);
  const [inputValue, setInputValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (timeSlotContext.timeSlotData.length > 0) {
      setInputValue(`${timeSlotContext.timeSlotData[0]} - ${timeSlotContext.timeSlotData[1]}`);
      setErrorMessage("");
    } else {
      setErrorMessage("Appointment Time is required");
    }
  }, [timeSlotContext.timeSlotData]);

  return (
    <Grid style={{ marginTop: "2%" }}>
      <Col span={4}>
        Appointment Time <span style={{ color: "red" }}> * </span>
      </Col>
      <Col span={8}>
        <TextInput
          value={inputValue}
          placeholder="Select Time Slot"
          error={errorMessage}
          disabled
        />
      </Col>
    </Grid>
  );
};

export default AppointmentTime;
