import {
  React,
  useEffect,
  useState,
  FormActions,
  GridLayout,
  Email,
  PhoneNumber,
  TextArea,
  OptionType,
  FormDataType,
  IplAppointmentCalender,
} from "../../Import";
import {
  appointmentCreationPrerequisites,
  createAppointment,
  getAppointment,
  editAppointment,
} from "../../Apis/apis";
import {
  validateEmail,
  validatePhoneNumber
} from "../../Libraries/ValidationUtils";
import { AppointmentFormProps } from "../../Models/ObtTypes";
import { useNotification } from "../../../../context/NotificationContext";
import { useNavigate } from "react-router-dom";

const AppointmentForm: React.FC<AppointmentFormProps> = (props) => {
  const { projectKey, updateLoadingState, tabValue, translation } = props;
  const [appointmentFormValues, setAppointmentFormValues] =
    useState<FormDataType>({});
  const [appointmentType, setAppointmentType] = useState<string>("1");
  const [appointmentDetails, setAppointmentDetails] = useState<{
    comments: string | undefined;
    email: string | undefined;
    phone: string | undefined;
  }>({
    comments: undefined,
    email: undefined,
    phone: undefined,
  });
  const [formOptions, setFormOptions] = useState<{
    appointmentTypes: OptionType[];
    tips: string;
  }>({
    appointmentTypes: [],
    tips: "",
  });
  const { setError, setSuccess, clearNotification } = useNotification();
  const navigate = useNavigate();
  const [timeSlots, setTimeSlots] = useState<Date[] | []>([]);

  useEffect(() => {
    if (["form", "edit"].includes(String(tabValue))) {
      if (String(props.id).length > 0) {
        fetchAppointment().then((details) => {
          setAppointmentDetails({
            phone: details.data.phone,
            email: details.data.email,
            comments: details.data.comments,
          });
          setAppointmentType(details.appointment_type[0]);

          setTimeSlots([
            new Date(details.start),
            new Date(details.finish),
          ]);
        });
      }

      appointmentPrerequisite().then((items) => {
        setFormOptions({
          appointmentTypes: items.appointment_types,
          tips: items.tips,
        });
        updateLoadingState(false);
      });
    }

    return () => {
      clearNotification();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue, props.id]);

  const fetchAppointment = async () => {
    return await getAppointment(props.id);
  };

  const appointmentPrerequisite = async () => {
    updateLoadingState(true);
    return await appointmentCreationPrerequisites(projectKey);
  };

  const updateAppointmentType = (appointmentType: string) => {
    setAppointmentType(appointmentType);
  };

  const formSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    let message = "Booking created successfully";

    const isEmailValid = validateEmail(String(appointmentFormValues.email));
    const isPhoneValid = validatePhoneNumber(String(appointmentFormValues.phone));
    const hasStartAndFinish = ["start", "finish"].every((value) =>
      appointmentFormValues.hasOwnProperty(value)
    );

    if (isEmailValid && isPhoneValid && hasStartAndFinish) {
      try {
        if (props.id.length > 0) {
          const formValues = !hasStartAndFinish
            ? {
                ...appointmentFormValues,
                start: timeSlots[0],
                finish: timeSlots[1],
              }
            : appointmentFormValues;

          await editAppointment(
            formValues,
            projectKey,
            appointmentType,
            props.id
          );

          message = "Booking Updated successfully";
        } else {
          await createAppointment(
            appointmentFormValues,
            projectKey,
            appointmentType
          );

          // Resets the form once booking is successfully created.
          setAppointmentDetails({
            phone: "",
            email: "",
            comments: "",
          });
        }

        setSuccess(message);

        setTimeout(() => {
          navigate("/ipl/list");
        }, 3000);
      } catch (error: any) {
        setError(error.message);
      }
    }
  };

  const formHandler = (input: FormDataType) => {
    setAppointmentFormValues((formData) => {
      return { ...formData, ...input };
    });
  };

  return (
    <GridLayout tips={formOptions.tips} type="form">
      <form onSubmit={formSubmit}>
        <IplAppointmentCalender
          nameStart="start"
          nameEnd="finish"
          optionValues={formOptions.appointmentTypes}
          appointmentType={appointmentType}
          updateAppointmentType={updateAppointmentType}
          saveInput={formHandler}
          projectKey={projectKey}
          timeSlots={timeSlots}
        />
        <PhoneNumber
          label={translation("phone_no")}
          placeHolderText={translation("phone_no_placeholder")}
          required={true}
          saveInput={formHandler}
          name="phone"
          value={appointmentDetails.phone}
        />
        <Email
          label={translation("email")}
          placeHolderText={translation("email_placeholder")}
          name="email"
          required={true}
          saveInput={formHandler}
          value={appointmentDetails.email}
        />
        <TextArea
          label={translation("additional_comments")}
          placeHolderText={translation("additional_comments_placeholder")}
          required={false}
          saveInput={formHandler}
          name="comments"
          value={appointmentDetails.comments}
        />
        <FormActions
          cancelText={translation("cancel_text")}
          submitText={translation("submit_text")}
        />
      </form>
    </GridLayout>
  );
};

export default AppointmentForm;
