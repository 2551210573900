import React, { useEffect, useState } from "react";
import { Select as MantineSelect } from "@mantine/core";
import { Grid, Col } from "@mantine/core";
import { SingleSelectObtType } from "../Models/ObtTypes";
import { OptionType } from "../Import";

const Select: React.FC<SingleSelectObtType> = (props) => {
  const [inputValue, setInputValue] = useState(props.value ?? "");

  useEffect(() => {
    props.updateAppointmentType(inputValue);
  }, [inputValue]);

  const SelectIterator = props.options!.map((option: OptionType) => ({
    value: String(option.value),
    label: option.label,
  }));

  return (
    <Grid>
      <Col span={4}>{props.label}</Col>
      <Col span={8}>
        <MantineSelect
          label=""
          placeholder="Select Appointment Type"
          value={inputValue}
          onChange={(value) => setInputValue(value ?? "")}
          data={SelectIterator}
        />
      </Col>
    </Grid>
  );
};

export default Select;
