export const getLocalDateString = (date: Date, format = "YYYY-MM-DD") => {
  if (format === "YYYY-MM-DD") {
    var year = date.toLocaleString("default", { year: "numeric" });
    var month = date.toLocaleString("default", { month: "2-digit" });
    var day = date.toLocaleString("default", { day: "2-digit" });
    return year + "-" + month + "-" + day;
  } else if (format === "DD-MONTH-YYYY") {
    return date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
  }
  return "";
};

export const getLocalTime = (date: Date, format = "HH:MM") => {
  return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};
