import React, { createContext, useContext, useState, ReactNode } from "react";

interface NotificationContextProps {
  error: string | null;
  success: string | null;
  warning: string | null;
  setError: (message: string | null) => void;
  setSuccess: (message: string | null) => void;
  setWarning: (message: string | null) => void;
  clearNotification: () => void;
}

export const NotificationContext = createContext<
  NotificationContextProps | undefined
>(undefined);

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [warning, setWarning] = useState<string | null>(null);

  const clearNotification = () => {
    setError(null);
    setSuccess(null);
    setWarning(null);
  };

  return (
    <NotificationContext.Provider
      value={{
        error,
        success,
        warning,
        setError,
        setSuccess,
        setWarning,
        clearNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error(
      "useNotification must be used within a NotificationProvider"
    );
  }
  return context;
};
