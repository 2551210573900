import axios from "axios";
import { FormDataType } from "../Import";

export const getAppointmentsData = async (projectKey: string) => {
  try {
    const apiUrl = `${process.env.REACT_APP_OBT_API_URL}?project_key=${projectKey}`;
    const response = await axios.get(apiUrl, { withCredentials: true });
    return response.data.data;
  } catch (error) {
    throw new Error("Error fetching appointments data");
  }
};

export const exportAppointmentsToCalendar = async (projectKey: string) => {
  try {
    const apiUrl = `${process.env.REACT_APP_OBT_API_URL}?project_key=${projectKey}`;
    await axios
      .get(apiUrl, {
        params: { format: "ics" },
        responseType: "blob",
        withCredentials: true,
      })
      .then((response) => {
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "align_ipl_appointments.ics");
        document.body.appendChild(link);
        link.click();
      });
  } catch (error) {
    throw new Error("Error exporting calendar data");
  }
};

export const getAppointment = async (id: string) => {
  try {
    const apiUrl = `${process.env.REACT_APP_OBT_API_URL}/${id}/edit`;
    const response = await axios.get(apiUrl, { withCredentials: true });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching appointment data");
  }
};

export const cancelAppointment = async (id: string) => {
  try {
    const apiUrl = `${process.env.REACT_APP_OBT_API_URL}/${id}`;
    const response = await axios.delete(apiUrl, { withCredentials: true });
    return response;
  } catch (error) {
    throw new Error("Error occurred while cancelling the appointment");
  }
};

export const appointmentCreationPrerequisites = async (projectKey: string) => {
  try {
    const apiUrl = `${process.env.REACT_APP_OBT_API_URL}/new`;
    const response = await axios.get(apiUrl, {
      params: { project_key: projectKey },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error(
      "Error fetching appointment creation prerequisites information"
    );
  }
};

export const createAppointment = async (
  formValues: FormDataType,
  projectKey: string,
  appointmentType: string,
) => {
  try {
    const apiUrl = process.env.REACT_APP_OBT_API_URL!;
    const response = await axios.post(apiUrl, formValues, {
      params: {
        project_key: projectKey,
        appointment_type: appointmentType
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error("Error creating appointment");
  }
};

export const editAppointment = async (
  formValues: FormDataType,
  projectKey: string,
  appointmentType: string,
  id: string,
) => {
  try {
    const apiUrl = `${process.env.REACT_APP_OBT_API_URL!}/${id}`;
    const response = await axios.put(apiUrl, formValues, {
      params: {
        project_key: projectKey,
        appointment_type: appointmentType
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error("Error updating appointment");
  }
};

export const getTimeslots = async (
  projectKey: string,
  appointmentTypeId: string
) => {
  try {
    const apiUrl = `${process.env.REACT_APP_OBT_API_URL}/timeslots`;
    const response = await axios.get(apiUrl, {
      params: {
        project_key: projectKey,
        appointment_type: appointmentTypeId,
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    throw new Error("Error fetching timeslots");
  }
};
