import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import i18n from './components/Translation/i18n';
import Alert from "./components/Obt/ObtComponents/Alert";
import React, { useEffect, useState } from "react";
import RouterObt from "./router";
import { LoadingOverlay } from "@mantine/core";
import { RouterProvider } from "react-router-dom";
import { useNotification } from "./context/NotificationContext";

const App = () => {
  const [userInfo, setUserInfo] = useState({});
  const { setError, error, success, warning, clearNotification } =
    useNotification();

  function renderAuthOptions(options: {
    authenticate: { invisalign: string };
  }) {
    window.location.href =
      options.authenticate.invisalign + "?origin=" + window.location;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gatewayHost = process.env.REACT_APP_AUTH_URL;
        const response = await fetch(gatewayHost!, { credentials: "include" });

        if (response.status === 401) {
          const payload = await response.json();
          renderAuthOptions(payload);
        } else if (response.ok) {
          const payload = await response.json();
          window.localStorage.setItem("gateway-user", JSON.stringify(payload));
          setUserInfo(payload);
          i18n.changeLanguage("en");
        }
      } catch (error) {
        setError(`Error fetching data: ${error}`);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <Alert
        error={error}
        success={success}
        warning={warning}
        clearNotification={clearNotification}
      />

      {Object.keys(userInfo).length > 0 ? (
        <RouterProvider router={RouterObt} />
      ) : (
        <LoadingOverlay visible={true} overlayBlur={2} />
      )}
    </div>
  );
};

export default App;
