import {
  React,
  useEffect,
  useState,
  LoadingOverlay,
  Box,
  Select,
  AppointmentTime,
  getLocalDateString,
  CalenderSlot,
  TimeslotJsonType,
  TimeslotType,
  TimeSlotContext,
  CalenderType,
} from "../../Import";
import { getTimeslots } from "../../Apis/apis";

const IplAppointmentCalender: React.FC<CalenderType> = (props) => {
  const [timeslots, setTimeslots] = useState<TimeslotJsonType>();
  const [timeSlotData, setTimeSlotData] = useState<Date[] | []>([]);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [slots, setSlots] = useState<TimeslotType[]>();
  const [dateValue, setDateValue] = useState<Date | null>();

  const displayAvailableSlots = (date: Date) => {
    setDateValue(date);
    setSlots(() => timeslots![getLocalDateString(date)].slots);
  };
  const setTimeSlotDataValue = (dateArr: Date[]) => {
    setTimeSlotData(dateArr);
    props.saveInput({ [props.nameStart]: dateArr[0] });
    props.saveInput({ [props.nameEnd]: dateArr[1] });
  };

  const timeslotsResponse = async () => {
    return await getTimeslots(props.projectKey, props.appointmentType);
  };

  useEffect(() => {
    if (props.timeSlots.length > 0) {
      setTimeSlotData(props.timeSlots);
    }
    setLoaderVisible(true);
    setSlots([]);
    timeslotsResponse().then((response) => {
      setTimeslots(() => response.data!.timeslots);
      setLoaderVisible(false);
    });
  }, [props.appointmentType]);

  return (
    <TimeSlotContext.Provider
      value={{
        timeSlotData: timeSlotData,
        setTimeSlotDataValue: setTimeSlotDataValue,
      }}
    >
      <Box>
        <LoadingOverlay visible={loaderVisible} overlayBlur={2} />
        <Select
          label="Appointment Types"
          options={props.optionValues}
          value={props.appointmentType}
          name="appointment_type"
          required={true}
          saveInput={props.saveInput}
          updateAppointmentType={props.updateAppointmentType}
        />
        <CalenderSlot
          timeslots={timeslots}
          displayAvailableSlots={displayAvailableSlots}
          slots={slots}
          dateValue={dateValue}
        />
        <AppointmentTime />
      </Box>
    </TimeSlotContext.Provider>
  );
};

export default IplAppointmentCalender;
