import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        new_appointment: "New Appointment",
        my_appointments: "My Appointments",
        phone_no: "Phone No",
        phone_no_placeholder: "Please Enter Phone No",
        email: "Email",
        email_placeholder: "Enter Email Address",
        additional_comments: "Additional Comments",
        additional_comments_placeholder: "Enter Additional Comments:",
        cancel_text: "Cancel",
        submit_text: "Schedule Now",
      },
    },
    eu: {
      translation: {
        key: "EU World IPL",
      },
    },
  },
  fallbackLng: "en", // Default language
  interpolation: {
    escapeValue: false, // React already escapes values, so no need for additional escaping
  },
});

export default i18n;
