import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import ConfirmationModal from "../../ObtComponents/ConfirmationModal";
import { Appointment } from "../../Models/ObtTypes";
import { AppointmentsTableProps } from "../../Models/ObtTypes";
import { React, useState, useEffect } from "../../Import";
import { Trash, PencilSquare, Download } from "react-bootstrap-icons";
import {
  getAppointmentsData,
  cancelAppointment,
  exportAppointmentsToCalendar,
} from "../../Apis/apis";
import { useNotification } from "../../../../context/NotificationContext";
import { useNavigate } from 'react-router-dom';

dayjs.extend(timezone);
dayjs.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone);

const AppointmentsTable: React.FC<AppointmentsTableProps> = (props) => {
  const { projectKey, updateLoadingState, tabValue } = props;
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState("");
  const { setError, setSuccess, clearNotification } = useNotification();
  const navigate = useNavigate();

  const fetchAppointmentsData = async () => {
    try {
      updateLoadingState(true);
      const data = await getAppointmentsData(projectKey);
      setAppointments(data);
      updateLoadingState(false);
    } catch (error: any) {
      setError(error.message);
      updateLoadingState(false);
    }
  };

  const handleCancelAppointment = (appointmentId: string) => {
    setSelectedAppointmentId(appointmentId);
    setShowConfirmModal(true);
  };

  const handleConfirmCancel = async () => {
    setShowConfirmModal(false);
    try {
      await cancelAppointment(selectedAppointmentId);

      fetchAppointmentsData();
      setSuccess("Booking successfully cancelled");
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleEditAppointment = (id: string) => {
    navigate(`/ipl/edit/${id}`);
  };

  const handleExport = async () => {
    exportAppointmentsToCalendar(projectKey);
  };

  useEffect(() => {
    if (tabValue === "list") {
      fetchAppointmentsData();
    }

    return () => {
      clearNotification();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  if (appointments.length === 0) {
    return <p>No appointments available.</p>;
  }

  return (
    <div>
      <table className={"table table-striped table-hover"}>
        <thead className={"table-light"}>
          <tr>
            <th style={{ width: "20%" }}>Date</th>
            <th style={{ width: "16%" }}>Appointment</th>
            <th style={{ width: "10%" }}>Email address</th>
            <th style={{ width: "9%" }}>Telephone</th>
            <th style={{ width: "20%" }}>Comments</th>
            <th style={{ width: "7%" }}>Zoom</th>
            <th style={{ width: "6%" }}>Export</th>
            <th style={{ width: "6%" }}>Cancel</th>
            <th style={{ width: "6%" }}>Edit</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment) => (
            <tr key={appointment.id}>
              <td>
                <span className={"btn btn-sm text-bg-secondary"}>{dayjs(appointment.date).format("dddd, MMMM D, YYYY h:mm A")} <span className={"badge text-bg-info"}>UTC {dayjs(appointment.date).format("Z")}</span></span>
              </td>
              <td>{appointment.appointment}</td>
              <td>{appointment.data.email}</td>
              <td>{appointment.data.phone}</td>
              <td>{appointment.data.comments}</td>
              <td>
                {appointment.data.zoom ? (
                  <a
                    className="btn btn-primary btn-sm"
                    href={appointment.data.zoom?.doctor_join_url}
                  >
                    Connect
                  </a>
                ) : (
                  ""
                )}
              </td>
              <td>
                <button
                  onClick={() => handleExport()}
                  className={"btn btn-default btn-sm icon-border"}
                >
                  <Download size={17} />
                </button>
              </td>
              <td>
                <button
                  onClick={() => handleCancelAppointment(appointment.id)}
                  className={"btn btn-default btn-sm icon-border"}
                >
                  <Trash size={17} />
                </button>
              </td>
              <td>
                <button
                  onClick={() => handleEditAppointment(appointment.id)}
                  className={"btn btn-default btn-sm icon-border"}
                >
                  <PencilSquare size={17} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ConfirmationModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        onConfirm={handleConfirmCancel}
        message="Appointment will be cancelled immediately."
      />
    </div>
  );
};

export default AppointmentsTable;
