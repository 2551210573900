import React from "react";
import { Container } from "@mantine/core";

const Tips: React.FC<{ tips?: string }> = (props) => {
  return (
    <Container size="sm" className="Form">
      <div dangerouslySetInnerHTML={{ __html: props.tips! }} />
    </Container>
  );
};

export default Tips;
