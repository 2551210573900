import React from "react";
import { Container } from "@mantine/core";
import { Grid } from "@mantine/core";
import TipsObt from "./Tips";
import { ReactNode } from "react";

interface GridLayoutProps {
  children: ReactNode;
  tips?: string;
  type: string;
}

const GridLayout: React.FC<GridLayoutProps> = (props) => {
  let renderGrid = <></>;

  if (props.type === "form") {
    renderGrid = (
      <Grid columns={18}>
        <Grid.Col span={2}></Grid.Col>
        <Grid.Col span={11} className="Form">
          <Container>{props.children}</Container>
        </Grid.Col>
        <Grid.Col span={4}>
          <TipsObt tips={props.tips} />
        </Grid.Col>
        <Grid.Col span={1}></Grid.Col>
      </Grid>
    );
  } else if (props.type === "list") {
    renderGrid = (
      <Grid columns={18}>
        <Grid.Col span={2}></Grid.Col>
        <Grid.Col span={14} className="Form">
          <Container>{props.children}</Container>
        </Grid.Col>
        <Grid.Col span={2}></Grid.Col>
      </Grid>
    );
  } else if (props.type === "tabs") {
    renderGrid = (
      <Grid columns={18}>
        <Grid.Col span={2}></Grid.Col>
        <Grid.Col span={14}>{props.children}</Grid.Col>
        <Grid.Col span={2}></Grid.Col>
      </Grid>
    );
  }

  return <React.Fragment>{renderGrid}</React.Fragment>;
};

export default GridLayout;
