import ErrorPage from "./ErrorPage";
import IplObt from "./components/Obt/ObtViews/Ipl/IplObt";
import EditAppointmentForm from "./components/Obt/ObtViews/Ipl/EditAppointmentForm";
import ObtLayout from "./components/Obt/ObtLayout";
import React from "react";
import { createBrowserRouter } from "react-router-dom";

const RouterObt = createBrowserRouter([
  {
    path: "/",
    element: <ObtLayout />,
    children: [
      { path: "/ipl/:tab", element: <IplObt /> },
      { path: "/ipl/edit/:id", element: <EditAppointmentForm /> }
    ],
    errorElement: <ErrorPage />,
  },
]);

export default RouterObt;
