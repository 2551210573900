import { React, useState, LoadingOverlay } from "../../Import";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import AppointmentForm from "./AppointmentForm";
import { ArrowRight } from "react-bootstrap-icons";

const EditAppointmentForm: React.FC = () => {
  const { t } = useTranslation();
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [projectKey] = useState(window.location.pathname.split("/")[1]);
  const { id } = useParams();

  const updateLoadingState = (newLoadingState: boolean) => {
    setLoaderVisible(newLoadingState);
  };

  return (
    <div>
      <LoadingOverlay visible={loaderVisible} overlayBlur={2} />

      <div className="row">
        <div className="col-md-11 text-end mt-3">
          <Link to="/ipl/list" className="btn btn-outline-primary">
            <ArrowRight size={17} />  Appointments Page
          </Link>
        </div>
      </div>

      <div className="row">
        <AppointmentForm
          translation={t}
          tabValue="edit"
          projectKey={projectKey}
          id={id}
          updateLoadingState={updateLoadingState}
        />
      </div>
    </div>
  );
};

export default EditAppointmentForm;
