import MobileMenu from "./MobileMenu/MobileMenu";
import React from "react";
import UserMenu from "./UserMenu/UserMenu";
import isLinksDataValid from "./is-links-data-valid";
import useStyles from "./IdsHeader.styles";
import { Container, Image, Text } from "@mantine/core";
import { IdsHeaderData } from "./types";

interface IdsHeaderProps extends React.ComponentPropsWithoutRef<"div"> {
  data: IdsHeaderData;
}

const defaultLogoData: IdsHeaderData["logo"] = {
  image:
    "https://storagy-advent-core-ppr.s3.amazonaws.com/storage/45f237e2-96d5-4b67-a670-657b9a76fc09.png",
  link: "/",
  external: true,
};

export function IdsHeader({ className, data, ...others }: IdsHeaderProps) {
  const { classes, cx } = useStyles();
  const logo = data.logo || defaultLogoData;

  if (!data || !isLinksDataValid(data.main_links)) {
    return null;
  }

  const mainLinks = data.main_links.map(
    ({ title, link, external, active }, index) => (
      <a
        key={index}
        className={cx(classes.link, { [classes.linkActive]: active })}
        href={link}
        target={external ? "_blank" : null!}
        rel="noopener noreferrer"
      >
        <Text className={classes.linkLabel}>{title}</Text>
      </a>
    )
  );

  return (
    <section className={cx(classes.wrapper, className)} {...others}>
      <Container>
        <header className={classes.header}>
          <a
            className={classes.logo}
            href={logo.link}
            target={logo.external ? "_blank" : null!}
            rel="noopener noreferrer"
          >
            <Image
              src="https://resources.invisalign.com/assets/align/ids_layout/invisalign-white-9b231703cd58f0fc11c40daffb990270.svg"
              width={150}
              height={35}
            />
          </a>
          <UserMenu user={data.user} user_links={data.user_links} />
          <MobileMenu
            user={data.user}
            user_links={data.user_links}
            main_links={data.main_links}
          />
        </header>

        <nav className={classes.nav}>{mainLinks}</nav>
      </Container>
    </section>
  );
}
