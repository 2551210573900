import React, { useContext } from "react";
import { TimeSlotPropType } from "../Models/ObtTypes";
import { getLocalTime } from "../Libraries/DateLibrary";
import { Button } from "@mantine/core";
import { TimeSlotContext } from "../Store/TimeSlotContext";

const Timeslot: React.FC<TimeSlotPropType> = (props) => {
  const context = useContext(TimeSlotContext);
  const handleTimeslotClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    context.setTimeSlotDataValue([
      new Date(props.slot.since!),
      new Date(props.slot.till!),
    ]);
  };

  return (
    <Button
      color="teal"
      onClick={handleTimeslotClick}
      size="md"
      fullWidth={true}
    >
      <span style={{ width: "100%" }}>
        {getLocalTime(new Date(props.slot.since!))}
      </span>
    </Button>
  );
};

export default Timeslot;
