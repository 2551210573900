import React from "react";

interface AlertProps {
  error: string | null;
  success: string | null;
  warning: string | null;
  clearNotification: () => void;
}

const Alert: React.FC<AlertProps> = ({
  error,
  success,
  warning,
  clearNotification,
}) => {
  return (
    <div className="row">
      <div className="col-6 offset-3">
        {error && (
          <span
            className="alert alert-danger align-items-center alert-dismissible"
            role="alert"
          >
            <strong>{error}</strong>
            <button
              type="button"
              className="btn-close"
              onClick={clearNotification}
              aria-label="Close"
            ></button>
          </span>
        )}

        {success && (
          <span
            className="alert alert-success align-items-center alert-dismissible"
            role="alert"
          >
            <strong>{success}</strong>
            <button
              type="button"
              className="btn-close"
              onClick={clearNotification}
              aria-label="Close"
            ></button>
          </span>
        )}

        {warning && (
          <span
            className="alert alert-warning align-items-center alert-dismissible"
            role="alert"
          >
            <strong>{warning}</strong>
            <button
              type="button"
              className="btn-close"
              onClick={clearNotification}
              aria-label="Close"
            ></button>
          </span>
        )}
      </div>
    </div>
  );
};

export default Alert;
