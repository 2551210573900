import React from "react";
import { Calendar } from "@mantine/dates";
import Timeslots from "./Timeslots";
import { Grid, Center } from "@mantine/core";
import { getLocalDateString } from "../Libraries/DateLibrary";
import { CalenderSlotObtType } from "../Models/ObtTypes";

const CalenderSlot = (props: CalenderSlotObtType) => {
  const dateFlag = (date: Date) => {
    return (
      !Object.keys(props.timeslots!).includes(getLocalDateString(date)) ||
      date.getDay() === 0 ||
      date.getDay() === 6
    );
  };

  return (
    <Grid>
      <Grid.Col span={8}>
        {!props.timeslots ? (
          ""
        ) : (
          <Calendar
            value={props.dateValue}
            fullWidth
            size="md"
            excludeDate={(date) => dateFlag(date)}
            onChange={props.displayAvailableSlots}
            dayStyle={(date) =>
              date.getDay() === 5 && date.getDate() === 13
                ? { color: "red" }
                : {}
            }
            styles={(theme) => ({
              cell: {
                border: `1px solid ${
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[4]
                    : theme.colors.gray[2]
                }`,
              },
              day: {
                borderRadius: 0,
                height: 70,
                fontSize: theme.fontSizes.lg,
              },
              weekday: { fontSize: theme.fontSizes.lg },
              weekdayCell: {
                fontSize: theme.fontSizes.xl,
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[5]
                    : theme.colors.gray[0],
                border: `1px solid ${
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[4]
                    : theme.colors.gray[2]
                }`,
                height: 70,
              },
            })}
          />
        )}
      </Grid.Col>

      <Grid.Col span={4}>
        <Center
          style={{ backgroundColor: "#6c757d", height: "9%", color: "#fff" }}
        >
          {props.dateValue
            ? getLocalDateString(props.dateValue, "DD-MONTH-YYYY")
            : "Select A Calender Date"}
        </Center>
        <Timeslots slots={props.slots!} />
      </Grid.Col>
    </Grid>
  );
};

export default CalenderSlot;
