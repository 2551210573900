import React, { useState } from "react";
import cx from "clsx";
import { Burger, Divider, Text, Transition } from "@mantine/core";
import isLinksDataValid from "../is-links-data-valid";
import { UserLabel } from "../UserLabel/UserLabel";
import useStyles, {
  USER_LINK_HEIGHT,
  USER_SEPARATOR_HEIGHT,
  USER_DROPDOWN_SPACING,
} from "./MobileMenu.styles";
import { IdsHeaderData } from "../types";

interface MobileMenuProps {
  main_links: IdsHeaderData["main_links"];
  user: IdsHeaderData["user"];
  user_links: IdsHeaderData["user_links"];
}

export default function MobileMenu({
  main_links,
  user,
  user_links,
}: MobileMenuProps) {
  const { classes } = useStyles();
  const [menuOpened, setMenuOpened] = useState(false);
  const [userDropdownOpened, setUserDropdownOpened] = useState(false);

  const toggleMenu = () => {
    if (menuOpened) {
      setUserDropdownOpened(false);
      setMenuOpened(false);
    } else {
      setMenuOpened(true);
    }
  };

  const mainLinks = isLinksDataValid(main_links)
    ? main_links.map(({ title, link, external, active }, index) => (
        <a
          key={index}
          className={cx(classes.mainLink, { [classes.mainLinkActive]: active })}
          href={link}
          target={external ? "_blank" : null!}
          rel="noopener noreferrer"
        >
          <Text className={classes.mainLinkLabel}>{title}</Text>
        </a>
      ))
    : null;

  const userLinks = isLinksDataValid(user_links)
    ? user_links.map((link, index) =>
        link.separator ? (
          <Divider my={4} />
        ) : (
          <Text
            component="a"
            className={classes.userLink}
            href={link.link}
            key={index}
            target={link.external ? "_blank" : null!}
          >
            {link.title}
          </Text>
        )
      )
    : null;

  const userDropdownHeight = isLinksDataValid(user_links)
    ? USER_DROPDOWN_SPACING +
      user_links.reduce(
        (acc, item) =>
          acc + (item.separator ? USER_SEPARATOR_HEIGHT : USER_LINK_HEIGHT),
        0
      )
    : 0;

  return (
    <div className={classes.wrapper}>
      <Burger opened={menuOpened} onClick={toggleMenu} color="gray" />

      <Transition
        mounted={menuOpened}
        timingFunction="ease"
        transition={{
          in: { transform: "scaleY(1)" },
          out: { transform: "scaleY(0)" },
          common: { transformOrigin: "top" },
          transitionProperty: "transform",
        }}
      >
        {(menuTransitionStyles) => (
          <nav className={classes.menu} style={menuTransitionStyles}>
            <div className={classes.userLabel}>
              <UserLabel
                user={user}
                onClick={() => setUserDropdownOpened((o) => !o)}
              />
            </div>

            <Transition
              mounted={userDropdownOpened}
              timingFunction="ease"
              transition={{
                in: { maxHeight: userDropdownHeight },
                out: { maxHeight: 0 },
                transitionProperty: "max-height",
              }}
            >
              {(transitionStyles) => (
                <div style={transitionStyles} className={classes.userDropdown}>
                  <div className={classes.userDropdownInner}>{userLinks}</div>
                </div>
              )}
            </Transition>

            {mainLinks}
          </nav>
        )}
      </Transition>
    </div>
  );
}
